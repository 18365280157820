.buttonPrimary {
    padding: 4px 14px 4px 12px;
    gap: 0px;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0px 1px 3px 0px #21212640, 0px 1px 0px 0px #FFFFFF17 inset, 0px 0px 0px 1px #282F96;
    
    color: white;
    background: #3038B4;

    border: none;

    font-size: 10px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
}

.buttonSecondary {
    padding: 4px 14px 4px 12px;
    gap: 0px;

    color: black;
    background: transparent;

    border: none;

    font-size: 10px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
}

.buttonBlack {
    padding: 4px 14px 4px 12px;
    gap: 0px;
    border-radius: 6px 6px 6px 6px;

    color: white;
    background: black;

    border: none;

    font-size: 10px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
}

.block {
    display: flex;
    align-items: center;

    padding: 20px;
    gap: 10px;
    border-radius: 12px 12px 12px 12px;
    height: 100%;

    background-color: white;
}

.blockShadow {
    box-shadow: 0px 24px 24px -12px #0E3F7E0A,
        0px 12px 12px -6px #0E3F7E0A,
        0px 6px 6px -3px #2A33460A,
        0px 3px 3px -1.5px #2A33460A,
        0px 1px 1px -0.5px #2A33450A,
        0px 0px 0px 1px #0E3F7E0A;
}

.noPadding {
    padding: 0px !important;
}

.blockImage {
    border-radius: 6px;
    border: 1px;
}


.listIndicator {
    font-size: 55px;
}

.teamImageContainer {
    width: 100%;
    height: 220px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .listIndicator {
        font-size: 170px !important;
    }
    .block {
        display: flex;
        align-items: center;
    
        padding: 48px;
        gap: 10px;
        border-radius: 12px 12px 12px 12px;
        height: 100%;
    
        background-color: white;
    }
    .buttonBlack {
        padding: 12px 24px 12px 24px;
        font-size: 14px;
    }
    .buttonPrimary {
        padding: 8px 24px 8px 24px;
        font-size: 14px;
    }
    .buttonSecondary {
        padding: 8px 24px 8px 24px;
        font-size: 14px;
    }
    .teamImageContainer {
        width: 100%;
        height: 320px;
    }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) { }