.nav {
    position: fixed;

    height: 81px;
    width: 100%;
    max-width: 1440px;

    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    z-index: 1000;
    background-color: #F5F7FB;
}

.nav p {
    margin: 0;
    padding: 10px;
}

.logoDisplay {
    display: inline-flex;
}

@media (min-width: 768px) {
    .nav {
        padding-left: 85px;
        padding-right: 85px;
    }
}