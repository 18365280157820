.modalContainer {
    background-color: rgba(0,0,0, 0.3);
    position: fixed;

    width: 100%;
    height: 100%;

    z-index: 2000;
}

.elementPadding {
    margin: 0;
    margin-bottom: 24px;
}

.modal {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: auto;
    background-color: white;
    height: fit-content;
    border-radius: 6px;
    padding: 24px;
}

.modal h3 {
    color: #131316;
    font-weight: bold;
    font-size: 20px;
}

.modal h4 {
    color: #131316;
    font-weight: 700;
    font-size: 16px;
}

.modal p {
    color: #596174;
    font-size: 16px;
}

.modal input {
    border: none;
    width: 100%;
    height: 33px;
    padding-top: 8px;
    padding-bottom: 8px;

    padding-left: 16px;
    padding-right: 16px;
    
    gap: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 1px #E8EAED, 0px 1px 3px rgba(33, 33, 38, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.07);
    border-radius: 6px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.modal textarea {
    border: none;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;

    padding-left: 16px;
    padding-right: 16px;
    
    gap: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 1px #E8EAED, 0px 1px 3px rgba(33, 33, 38, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.07);
    border-radius: 6px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    resize: none;
}

.modal button {
    width: 100%;
    text-align: center;
    font-size: 16px !important;
    padding: 8px;
}

.successDialog {
    position: fixed;
    bottom: 30px;
    right: 30px;
    
    background-color: white;
    height: fit-content;
    width: fit-content;
    border-radius: 6px;

    display: flex;

    z-index: 1000;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0px 24px 24px -12px #0E3F7E0A,
        0px 12px 12px -6px #0E3F7E0A,
        0px 6px 6px -3px #2A33460A,
        0px 3px 3px -1.5px #2A33460A,
        0px 1px 1px -0.5px #2A33450A,
        0px 0px 0px 1px #0E3F7E0A;
}

.successDialog h4 {
    color: #131316;
    font-weight: bold;
    font-size: 14px;
    margin: 0px;
    line-height: 20px;
}

.successDialog p {
    color: #596174;
    font-size: 14px;
    margin: 0px;
    margin-top: 2.5px;
    line-height: 16px;
}

.successDialog i {
    font-size: 25px;
    font-weight: bold;
    padding-left: 32px;
    cursor: pointer;
    color: #596174;
    align-self: center;
}

@media (min-width: 768px) {
    .modal {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: white;
        height: fit-content;
        border-radius: 6px;
        padding: 24px;
        width: 380px;
    }

    .successDialog {
        position: fixed;
        bottom: 30px;
        right: 70px;
        
        background-color: white;
        height: fit-content;
        width: fit-content;
        border-radius: 6px;
    }
}