.banner {
    min-height: 50vh;
}

.largeImage {
    width: 100%;
    height: auto;
}

.titleText {
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
}

.titleTextPhase1 {
    font-size: 33px;
    font-weight: 700;
    text-align: left;
    line-height: 40px;
}

.description {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: left;
    max-width: 600px;
}



@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) { 
    .titleText {
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 100px;
        text-align: left;
    }

    .titleTextPhase1 {
        font-size: 38px;
        font-weight: 700;
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .titleText {
        font-size: 6rem;
        font-weight: 700;
        line-height: 124.8px;
        text-align: left;
    }

    .titleTextPhase1 {
        font-size: 60px;
        font-weight: 700;
        text-align: left;
        line-height: 80px;
    }
}

@media (min-width: 1320px) { 
    .titleTextPhase1 {
        font-size: 69px;
    }
}