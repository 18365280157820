:root {
    --primary: #3038B4;
    --secondary: #596174;
}
  
html {
    padding: 0;
    margin: 0;

    background-color: #F5F7FB;

    min-width: 320px;
    display: flex;
    justify-content: center;
}

.bodyInfo {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1440px;
}

.transparentBg {
    background-color: transparent !important;
}

.primaryBg {
    background-color: var(--primary) !important;
}

.primaryColor {
    color: var(--primary) !important;
}

.secondaryColor {
    color: var(--secondary) !important;
}

.row {
    margin: 0px;
}

/*.sectionMargin {
    margin-top: 100px;
}*/

.titleFont {
    font-family: "forma-djr-deck", sans-serif;
    font-weight: 700;
    font-style: normal;
}
.headerText {
    font-size: 38px;
    font-weight: 700;
    line-height: 45.6px;
    text-align: center;

    color: #131316;

    font-family: "forma-djr-deck", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.SubHeaderText {
    font-size: 26px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: center;

    color: #131316;
}

.SubHeaderText2 {
    font-size: 18px;

    font-weight: 700;

    color: #131316;
}

.contactUsText {
    font-size: 48px;
}

.contactUsDescription {
    font-size: 16px;
}

.BodyText {
    font-size: 16px;
}

.headingPadding {
    margin-bottom: 0px;
    padding-bottom: 32px;
}

@media (min-width: 576px) { }

@media (min-width: 768px) {
    .bodyInfo {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 992px) {
    .bodyInfo {
        padding-left: 70px;
        padding-right: 70px;
    }
    .contactUsText {
        font-size: 104px;
    }
    .contactUsDescription {
        font-size: 24px;
    }
 }

@media (min-width: 1200px) { }

@media (min-width: 1400px) { }